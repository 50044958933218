import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import * as fb from '../../../api/firebase.js';

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT_PASSWORD = "forgotPassword";
export const UPDATE_USER = "updateUserDetails";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";


const state = {
  errors: null,
  user: {},
};

const getters = {
  currentUser(state) {
    return state.user;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      fb.auth.signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          fb.usersCollection.where("user_id", "==", user.uid).where("isCoach", "==", true).get().then((doc) => {
            if (doc.docs.length > 0) {
              context.commit(SET_AUTH, user);
              resolve(user);
            } else {

              fb.usersCollection.where("user_id", "==", user.uid).where("isAdmin", "==", true).get().then((doc) => {
                if (doc.docs.length > 0) {
                  context.commit(SET_AUTH, user);
                  resolve(user);
                } else {
                  
                  fb.usersCollection.where("user_id", "==", user.uid).where("isEmployee", "==", true).get().then((doc) => {
                    if (doc.docs.length > 0) {
                      context.commit(SET_AUTH, user);
                      resolve(user);
                    } else {

                      fb.usersCollection.where("user_id", "==", user.uid).where("isVendor", "==", true).get().then((doc) => {
                        if (doc.docs.length > 0) {
                          context.commit(SET_AUTH, user);
                          resolve(user);
                        } else {
                          context.commit(SET_ERROR, {});
                          reject("This is not a valid coach account!");
                        }
                      }).catch((error) => {
                        context.commit(SET_ERROR, error);
                        reject("This is not a valid coach account!");
                      })
                    }
                  }).catch((error) => {
                    context.commit(SET_ERROR, error);
                    reject("This is not a valid coach account!");
                  })

                }
              }).catch((error) => {
                context.commit(SET_ERROR, error);
                reject("This is not a valid coach account!");
              })
              
            }
          }).catch((error) => {
            context.commit(SET_ERROR, error);
            reject("This is not a valid coach account!");
          })
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          context.commit(SET_ERROR, error);
          reject(errorMessage);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [FORGOT_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      fb.auth.sendPasswordResetEmail(credentials.email).then(function () {
        resolve("A recovery email has been sent.")
      }).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        reject(errorMessage);
      });
    });
  },
  async [UPDATE_USER](context) {
    var user = await fb.firebase.getCurrentUser()
    fb.usersCollection.where("user_id", "==", user.uid).where("isCoach", "==", true).onSnapshot((snapshot) => {
      if (snapshot.docs.length > 0) {
        snapshot.docs.map((doc) => {
          state.user = doc.data()
        })
      }
    }, (error) => { })
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.user = user;
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.user = {};
    state.errors = {};
    fb.auth.signOut()
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};