<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <keep-alive max="5" include="['dashboard', 'adminfeed']">
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    
    <b-modal id="bv-modal-permission" ref="permissionModal" centered hide-header>
      <div class="d-block text-center">
        <h3>Permission required for updates</h3>
      </div>
      <div slot="modal-footer">
        <b-btn variant="primary">Allow</b-btn>
      </div>
    </b-modal>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
// Main demo style scss
@import "assets/sass/style.vue";
// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<style>
.btn-ingomublue,
.btn-ingomublue:hover {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-ingomugreen,
.btn-ingomugreen:hover {
  color: #fff;
  background-color: #46c346;
  border-color: #46c346;
}

.btn.btn-light-primary {
  color: #fff;
  background-color: #3b5998;
  border-color: transparent;
}

.label.label-ingomugreen {
  color: #fff;
  background-color: #46c346;
}

.hidden_header {
  display: none;
}

.text-ingomublue {
  color: #3b5998 !important;
}

.text-ingomugreen {
  color: #46c346 !important;
}

.br-42 {
  border-radius: 0.42rem;
}

.text-bold {
  font-weight: bold !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.bg-ingomublue {
  background-color: #3b5998 !important;
}

.bg-ingomugreen {
  background-color: #46c346 !important;
}

.section-title-h3 {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.hidden {
  display: none;
}

.activeClass {
  display: block;
}

.wave.wave-ingomublue {
  background-color: rgba(59, 89, 152, 0.9) !important;
}

.wave.wave-ingomugreen {
  background-color: rgba(70, 195, 70, 0.9) !important;
}

.floatright {
  float: right;
}

.text-disabled {
  color: gray;
}

.w100 {
  width: 100px;
}

.h100 {
  height: 100px;
}

.w15 {
  width: 15px;
}

.h15 {
  height: 15px;
}

.mh-0 {
  min-height: 0 !important;
}

.obcover {
  object-fit: cover;
}

.w-60 {
  width: 60% !important;
}

.fs-15 {
  font-size: 1.5rem !important;
}

@media (min-width: 992px) {
  .header-fixed.header-bottom-enabled .wrapper {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .header-fixed.header-bottom-enabled .header {
    height: 70px;
  }
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import Loader from "@/view/content/Loader.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import { mapGetters } from "vuex";

export default {
  name: "MetronicVue",
  components: {
    KTHeaderMobile,
    Loader,
    KTScrollTop,
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>