import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { FormSelectPlugin } from 'bootstrap-vue';

const state = {
    blogList: [],
    blogItem: [],
    catList: []
}

const getters = {
    blogList: state => state.blogList,
    blogItem: state => state.blogItem,
    catList: state => state.catList
}

const mutations = {
    SET_BLOG_ITEM(state, payload) {
        state.blogItem = payload.blogArray
    }
}

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const actions = {
    getBlogList: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('blogList',
        fb.blogCollection
            .orderBy("date", "desc")
        )
    }),
    getBlogItem(context, payload) {
        fb.blogCollection.doc(payload.blogId)
        .get()
            .then((doc) => {
                if (doc.exists) {
                    context.commit('SET_BLOG_ITEM', { blogArray: doc.data() })
                }
            });
    },
    getCategoryList: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('catList',
        fb.blogCatCollection
            .where("online", "==", true)
            .orderBy("order")
        )
    }),
    updateBlogStatus(context, payload) {
        fb.blogCollection.doc(payload.blogId).update({
            online: payload.status
        })
    },
    updateBlogArticle(context, form) {
        return new Promise(async (resolve, reject) => {
            let blogId = form.id;
            let slug = slugify(form.title)
            let catSlug = slugify(form.category)
            fb.blogCollection.doc(blogId).update({
                body: form.body,
                category: form.category,
                catslug: catSlug,
                date: fb.firebase.firestore.Timestamp.fromDate(new Date(form.date)),
                slug: slug,
                tags: form.tags,
                title: form.title,
                url1: form.url1,
                url2: form.url2
            })
            .then(() => {
                if (form.smphoto !== "" && form.smphoto !== null) {
                    let smfirstPhoto = form.smphoto.replace('data:image/jpeg;base64,', '')
                    let smnewPhoto = smfirstPhoto.replace('data:image/png;base64,', '')
                    var smref = fb.storage.child('blog/' + slug + "-sm" + '.jpg')
                    smref.putString(smnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(blogId).update({
                                smimage: link
                            })
                        })
                    })
                }
                if (form.mdphoto !== "" && form.mdphoto !== null) {
                    let mdfirstPhoto = form.mdphoto.replace('data:image/jpeg;base64,', '')
                    let mdnewPhoto = mdfirstPhoto.replace('data:image/png;base64,', '')
                    var mdref = fb.storage.child('blog/' + slug + "-md" + '.jpg')
                    mdref.putString(mdnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(blogId).update({
                                mdimage: link
                            })
                        })
                    })
                }
                if (form.lgphoto !== "" && form.lgphoto !== null) {
                    let lgfirstPhoto = form.lgphoto.replace('data:image/jpeg;base64,', '')
                    let lgnewPhoto = lgfirstPhoto.replace('data:image/png;base64,', '')
                    var lgref = fb.storage.child('blog/' + slug + "-lg" + '.jpg') 
                    if (form.url1 != "" && form.url1 != null) {
                        lgref = fb.storage.child(encodeURI(form.url1 + '.jpg'))
                    } 
                    lgref.putString(lgnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(blogId).update({
                                lgimage: link
                            })
                        })
                    })
                }
                if (form.xlphoto !== "" && form.xlphoto !== null) {
                    let xlfirstPhoto = form.xlphoto.replace('data:image/jpeg;base64,', '')
                    let xlnewPhoto = xlfirstPhoto.replace('data:image/png;base64,', '')
                    var xlref = fb.storage.child('blog/' + slug + "-xl" + '.jpg')
                    if (form.url2 != "" && form.url2 != null) {
                         xlref = fb.storage.child(encodeURI(form.url2 + '.jpg'))
                    } 
                    xlref.putString(xlnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(blogId).update({
                                xlimage: link
                            })
                        })
                    })
                }

            })
            .then(() => {
                resolve()
            })
        })
    },
    insertBlogArticle(context, form) {
        return new Promise(async (resolve, reject) => {
            let slug = slugify(form.title)
            let catSlug = slugify(form.category)

            fb.blogCollection.add({
                body: form.body,
                category: form.category,
                catslug: catSlug,
                date: fb.firebase.firestore.Timestamp.fromDate(new Date(form.date)),
                online: false,
                slug: slug,
                tags: form.tags,
                title: form.title,
                smimage: "",
                mdimage: "",
                lgimage: "",
                xlimage: "",
                url1: form.url1,
                url2: form.url2
            })
            .then((docRef) => {
                if (form.smphoto !== "" && form.smphoto !== null) {
                    let smfirstPhoto = form.smphoto.replace('data:image/jpeg;base64,', '')
                    let smnewPhoto = smfirstPhoto.replace('data:image/png;base64,', '')
                    var smref = fb.storage.child('blog/' + slug + "-sm" + '.jpg')
                    smref.putString(smnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(docRef.id).update({
                                smimage: link
                            })
                        })
                    })
                }
                if (form.mdphoto !== "" && form.mdphoto !== null) {
                    let mdfirstPhoto = form.mdphoto.replace('data:image/jpeg;base64,', '')
                    let mdnewPhoto = mdfirstPhoto.replace('data:image/png;base64,', '')
                    var mdref = fb.storage.child('blog/' + slug + "-md" + '.jpg')
                    mdref.putString(mdnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(docRef.id).update({
                                mdimage: link
                            })
                        })
                    })
                }
                if (form.lgphoto !== "" && form.lgphoto !== null) {
                    let lgfirstPhoto = form.lgphoto.replace('data:image/jpeg;base64,', '')
                    let lgnewPhoto = lgfirstPhoto.replace('data:image/png;base64,', '')
                    var lgref = fb.storage.child('blog/' + slug + "-lg" + '.jpg') 
                    if (form.url1 != "" && form.url1 != null) {
                        lgref = fb.storage.child(encodeURI(form.url1 + '.jpg'))
                    } 
                    lgref.putString(lgnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(docRef.id).update({
                                lgimage: link
                            })
                        })
                    })
                }
                if (form.xlphoto !== "" && form.xlphoto !== null) {
                    let xlfirstPhoto = form.xlphoto.replace('data:image/jpeg;base64,', '')
                    let xlnewPhoto = xlfirstPhoto.replace('data:image/png;base64,', '')
                    var xlref = fb.storage.child('blog/' + slug + "-xl" + '.jpg')
                    if (form.url2 != "" && form.url2 != null) {
                         xlref = fb.storage.child(encodeURI(form.url2 + '.jpg'))
                    } 
                    xlref.putString(xlnewPhoto, 'base64').then(function (snapshot) {
                        snapshot.ref.getDownloadURL().then((link) => {
                            fb.blogCollection.doc(docRef.id).update({
                                xlimage: link
                            })
                        })
                    })
                }
            })
            .then(() => {
                resolve()
            })
        });

    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
