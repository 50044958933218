import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firebase-messaging';
import IVS from "aws-sdk/clients/ivs";

var firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_KEY_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage().ref()
const functions = firebase.functions();
var messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// collection references
const usersCollection = db.collection('users')
const coachListCollection = db.collection('coach_list')
const coachesCollection = db.collection('coaches')
const coachSubscribe = db.collection('coach_subscribe')
const feedcommentsCollection = db.collectionGroup("feedcomments")
const feedreplyCollection = db.collectionGroup("feedreplies")
const sessionsCollection = db.collectionGroup("sessions")
const livestreamRecording = db.collection("livestream_recording")
const coachCategories = db.collection("coach_categories")
const faqCollection = db.collection("help_coaches")
const calCollection = db.collection("livestream_calendar")
const blogCollection = db.collection("blog")
const blogCatCollection = db.collection("blog_categories")
const freeUsersCollection = db.collection("free_users")
const corpClientsCollection = db.collection("corporate_clients")
const corpUsersCollections = db.collection("corporate_users")
const generalNotifications = db.collection("sent_general_notifications")
const userNotifications = db.collection("notifications")
const feedCollection = db.collection("feed")
const coachMessagesCollection = db.collection("coach_messages")
const changedExipreDateCollection = db.collection("free_users_change")
const mlCollection = db.collection("ml_versions")

const aws = require("aws-sdk");
aws.config.update({
  apiVersion: "2006-03-01",
  accessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRETKEY,
  region: process.env.VUE_APP_AWS_REGION,
});
const ivs = new aws.IVS({ apiVersion: "2020-07-14" });

export {
  firebase,
  db,
  auth,
  storage,
  functions,
  usersCollection,
  coachListCollection,
  coachesCollection,
  coachSubscribe,
  messaging,
  sessionsCollection,
  ivs,
  livestreamRecording,
  coachCategories,
  faqCollection,
  calCollection,
  blogCollection,
  blogCatCollection,
  freeUsersCollection,
  corpUsersCollections,
  corpClientsCollection,
  generalNotifications,
  userNotifications,
  feedCollection,
  feedcommentsCollection,
  feedreplyCollection,
  coachMessagesCollection,
  changedExipreDateCollection,
  mlCollection
}