import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    corpList: [],
    corpDetails: [],
    codeGroupList: [],
    codeGroupDetails: [],
    codeList: [],
    paidList: []
}

const getters = {
    corpList: state => state.corpList,
    corpDetails: state => state.corpDetails,
    codeGroupList: state => state.codeGroupList,
    codeGroupDetails: state => state.codeGroupDetails,
    codeList: state => state.codeList,
    paidList: state => state.paidList
}

const actions = {
    updateCorpUserOnlineStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecorpuseronlinestatus");
            updateOnlineCreds({
                status: status.status,
                corpuserid: status.corpuserid
            })
            resolve()
        })
    },
    updateCorpOnlineStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecorponlinestatus");
            updateOnlineCreds({
                status: status.status,
                corpid: status.corpid
            })
            resolve()
        })
    },
    updateCodeGroup(context, form) {
        return new Promise(async (resolve, reject) => {
            let formData = {
                corpid: form.corpid,
                codegroupid: form.codegroupid,
                startdate: form.startdate,
                enddate: form.enddate,
                online: form.online
            }
            var updateCode = fb.functions.httpsCallable("updatecodegroupstatus");
            updateCode(formData)
            resolve()
        })
    },
    getCorpList(context, payload) {
        fb.corpClientsCollection
            .orderBy("startdate", "desc")
            .onSnapshot((querySnapshot) => {
                let corpArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    corpArray.push(data)
                })
                state.corpList = corpArray;
            });
    },
    getCorpDetails(context, payload) {
        fb.corpClientsCollection
            .doc(payload.corpId)
            .onSnapshot((doc) => {
                state.corpDetails = doc.data()
            });
    },
    getCodeGroupList(context, payload) {
        fb.corpClientsCollection
            .doc(payload.corpId)
            .collection("code_group")
            .orderBy("created", "desc")
            .onSnapshot((querySnapshot) => {
                let codeArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    codeArray.push(data)
                })
                state.codeGroupList = codeArray;
            });
    },
    getCodeGroupListDetails(context, payload) {
        fb.corpClientsCollection
            .doc(payload.corpId)
            .collection("code_group")
            .doc(payload.codegroupId)
            .onSnapshot((doc) => {
                state.codeGroupDetails = doc.data()
            });
    },
    getCodes(context, payload) {
        fb.corpUsersCollections
            .where("codegroup_id", "==", payload.codegroupId)
            .orderBy("code")
            .onSnapshot((querySnapshot) => {
                let codeArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    codeArray.push(data)
                })
                state.codeList = codeArray;
            });
    },
    getPaidUsers(context, payload) {
        fb.corpUsersCollections
            .where("corporate_id", "==", payload.corpid)
            .where("payerid", "!=", null)
            .orderBy("payerid")
            .orderBy("redeem_date", "desc")
            .onSnapshot((querySnapshot) => {
                let codeArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    codeArray.push(data)
                })
                state.paidList = codeArray;
            })
    },
    createNewCodes(context, form) {
        return new Promise(async (resolve, reject) => {
            let formData = {
                startdate: form.startdate,
                enddate: form.enddate,
                numberlicenses: form.number_licenses,
                corpid: form.corpid
            }
            var createCodes = fb.functions.httpsCallable("createnewcodes");
            createCodes(formData)
            resolve()
        });
    },
    createNewCompany(context, form) {
        return new Promise(async (resolve, reject) => {
            let profileLink = ""
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            if (form.companyphoto !== "" && form.companyphoto !== null) {
                let companyfirstPhoto = form.companyphoto.replace('data:image/jpeg;base64,', '')
                let companynewPhoto = companyfirstPhoto.replace('data:image/png;base64,', '')
                var companyref = fb.storage.child('company/' + result + "-company" + '.jpg')
                let companysnapshot = await companyref.putString(companynewPhoto, 'base64')
                profileLink = await companysnapshot.ref.getDownloadURL()
            }

            var faqDict = []
            if (form.question1 != "" && form.answer1 != "" && form.select1 != "") {
                let dict1 = {
                    answer: form.answer1, question: form.question1, online: form.select1, sortorder: 1
                }
                faqDict.push(dict1)
            }
            if (form.question2 != "" && form.answer2 != "" && form.select2 != "") {
                let dict2 = {
                    answer: form.answer2, question: form.question2, online: form.select2, sortorder: 2
                }
                faqDict.push(dict2)
            }
            if (form.question3 != "" && form.answer3 != "" && form.select3 != "") {
                let dict3 = {
                    answer: form.answer3, question: form.question3, online: form.select3, sortorder: 3
                }
                faqDict.push(dict3)
            }
            if (form.question4 != "" && form.answer4 != "" && form.select4 != "") {
                let dict4 = {
                    answer: form.answer4, question: form.question4, online: form.select4, sortorder: 4
                }
                faqDict.push(dict4)
            }
            if (form.question5 != "" && form.answer5 != "" && form.select5 != "") {
                let dict5 = {
                    answer: form.answer5, question: form.question5, online: form.select5, sortorder: 5
                }
                faqDict.push(dict5)
            }
            if (form.question6 != "" && form.answer6 != "" && form.select6 != "") {
                let dict6 = {
                    answer: form.answer6, question: form.question6, online: form.select6, sortorder: 6
                }
                faqDict.push(dict6)
            }
            if (form.question7 != "" && form.answer7 != "" && form.select7 != "") {
                let dict7 = {
                    answer: form.answer7, question: form.question7, online: form.select7, sortorder: 7
                }
                faqDict.push(dict7)
            }

            let slug = form.name
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');

            let formData = {
                name: form.name,
                description: form.description,
                corpimage: profileLink,
                faq: faqDict,
                slug: slug,
                video: form.video,
                videothumbnail: form.videothumbnail,
                ispaid: form.isPaid,
                price: form.price,
                semi: form.semi,
                bestvalue: form.bestvalue
            }

            var createNewCompany = fb.functions.httpsCallable("createnewcompany");
            createNewCompany(formData)
            resolve()
        });
    },
    updateCompany(context, form) {
        return new Promise(async (resolve, reject) => {
            let profileLink = ""
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            if (form.companyphoto !== "" && form.companyphoto !== null) {
                let companyfirstPhoto = form.companyphoto.replace('data:image/jpeg;base64,', '')
                let companynewPhoto = companyfirstPhoto.replace('data:image/png;base64,', '')
                var companyref = fb.storage.child('company/' + result + "-company" + '.jpg')
                let companysnapshot = await companyref.putString(companynewPhoto, 'base64')
                profileLink = await companysnapshot.ref.getDownloadURL()
            }

            var faqDict = []
            if (form.question1 != "" && form.answer1 != "") {
                let dict1 = {
                    answer: form.answer1, question: form.question1, online: form.select1, sortorder: 1
                }
                faqDict.push(dict1)
            }
            if (form.question2 != "" && form.answer2 != "") {
                let dict2 = {
                    answer: form.answer2, question: form.question2, online: form.select2, sortorder: 2
                }
                faqDict.push(dict2)
            }
            if (form.question3 != "" && form.answer3 != "") {
                let dict3 = {
                    answer: form.answer3, question: form.question3, online: form.select3, sortorder: 3
                }
                faqDict.push(dict3)
            }
            if (form.question4 != "" && form.answer4 != "") {
                let dict4 = {
                    answer: form.answer4, question: form.question4, online: form.select4, sortorder: 4
                }
                faqDict.push(dict4)
            }
            if (form.question5 != "" && form.answer5 != "") {
                let dict5 = {
                    answer: form.answer5, question: form.question5, online: form.select5, sortorder: 5
                }
                faqDict.push(dict5)
            }
            if (form.question6 != "" && form.answer6 != "" && form.select6 != "") {
                let dict6 = {
                    answer: form.answer6, question: form.question6, online: form.select6, sortorder: 6
                }
                faqDict.push(dict6)
            }
            if (form.question7 != "" && form.answer7 != "" && form.select7 != "") {
                let dict7 = {
                    answer: form.answer7, question: form.question7, online: form.select7, sortorder: 7
                }
                faqDict.push(dict7)
            }

            let formData = {
                name: form.name,
                description: form.description,
                corpimage: profileLink,
                faq: faqDict,
                video: form.video,
                videothumbnail: form.videothumbnail,
                corpid: form.corpid,
                ispaid: form.isPaid,
                price: form.price,
                semi: form.semi,
                bestvalue: form.bestvalue
            }

            var updateCompany = fb.functions.httpsCallable("updatecompany");
            updateCompany(formData)
            resolve()
        });
    }

}

export default {
    state,
    getters,
    actions
}
