import * as fb from '../../../api/firebase.js';
import { firestoreAction } from 'vuexfire'

const state = {
    programDetail: [],
    programVideo: {},
    programCoachDetail: [],
    userProgramCoachDetail: [],
    programCoachList: [],
    adminCoachDetails: [],
    programCoaches: [],
    loadMoreLastCoaches: null,
    moreCoachesPending: true,
    programSubscribers: [],
    latestProgramSubscribers: [],
    loadMoreLastSubscribers: null,
    moreSubscribersPending: true,
    programSubscriberList: [],
    programSubscriberDetail: [],
    programSessions: [],
    programSessionDetail: [],
    categories: [],
    chatHistory: [],
    videoIssues: [],
    issuesCount: 0,
    adminCoachList: [],
}

const mutations = {
    SET_COACHES_BATCH(state, payload) {
        state.programCoaches = payload.coachArray
    },
    SET_LATEST_SUBSCRIBERS_BATCH(state, payload) {
        state.latestProgramSubscribers = payload.subscriberArray
    },
    MERGE_COACHES_BATCH(state, payload) {
        state.programCoaches = state.programCoaches.concat(payload.coachArray)
    },
    SET_LOADMORE_LAST_COACHES(state, payload) {
        state.loadMoreLastCoaches = payload.lastVisible
    },
    ALL_COACHES_LOADED(state) {
        state.moreCoachesPending = false
    },
    RESET_ALL(state) {
        state.programCoaches = []
        state.loadMoreLastCoaches = null
        state.moreCoachesPending = true
    },
    MERGE_SUBSCRIBERS_BATCH(state, payload) {
        state.programSubscribers = state.programSubscribers.concat(payload.subscriberArray)
    },
    SET_LOADMORE_LAST_SUBSCRIBERS(state, payload) {
        state.loadMoreLastSubscribers = payload.lastVisible
    },
    ALL_SUBSCRIBERS_LOADED(state) {
        state.moreSubscribersPending = false
    },
    RESET_ALL_SUBSCRIBERS(state) {
        state.programSubscribers = []
        state.loadMoreLastSubscribers = null
        state.moreSubscribersPending = true
    },
    RESET_VIDEOISSUES(state) {
        state.videoIssues = []
    },
    RESET_CHATHISTORY(state) {
        state.chatHistory = []
    }
}

const getters = {
    programDetail: state => state.programDetail,
    programCoachDetail: state => state.programCoachDetail,
    userProgramCoachDetail: state => state.userProgramCoachDetail,
    programVideo: state => state.programVideo,
    programCoachList: state => state.programCoachList,
    programCoaches: state => state.programCoaches,
    programMoreCoaches: state => state.moreCoachesPending,
    programSubscribers: state => state.programSubscribers,
    programMoreSubscribers: state => state.moreSubscribersPending,
    programSubscriberList: state => state.programSubscriberList,
    programSubscriberDetail: state => state.programSubscriberDetail,
    programSessions: state => state.programSessions,
    programSessionDetail: state => state.programSessionDetail,
    categories: state => state.categories,
    chatHistory: state => state.chatHistory,
    videoIssues: state => state.videoIssues,
    issuesCount: state => state.issuesCount,
    latestProgramSubscribers: state => state.latestProgramSubscribers,
    adminCoachList: state => state.adminCoachList,
    adminCoachDetails: state => state.adminCoachDetails
}

const customSerializer = (doc) => {
    const data = doc.data()
    // adds _doc to be used to paginate
    Object.defineProperty(data, '_doc', { value: doc })
    // adds id as enumerable attribute so we can easily access it
    Object.defineProperty(data, 'id', { value: doc.id, enumerable: true })
    return data
}

const actions = {
    getCoachCategories: firestoreAction((context, payload) => {
        fb.coachCategories
            .where("online", "==", true)
            .orderBy("category")
            .get()
            .then((querySnapshot) => {
                state.categories = querySnapshot.docs.map(doc => doc.data())
            })

    }),
    getProgramDetails: firestoreAction((context) => {
        fb.firebase.getCurrentUser().then((user) => {
            context.bindFirestoreRef('programDetail', fb.coachListCollection.where("coach.user_id", "==", user.uid).where("online", "==", true))
        })
    }),
    getProgramCoachDetail: firestoreAction((context, payload) => {
        fb.coachListCollection.doc(payload.coachId).get()
            .then((doc) => {
                if (doc.exists) {
                    let course = doc.data()
                    state.programCoachList = course
                    state.programVideo = { smallthumbnail: course.coach.largeImage, video: course.video }
                    fb.coachesCollection.where("user_id", "==", course.coach.user_id).limit(1).get()
                        .then((querySnapshot) => {
                            state.programCoachDetail = querySnapshot.docs.map(doc => doc.data())
                        })
                }
            })
    }),
    getCurrentCoachDetail: firestoreAction(async (context, payload) => {
        let user = await fb.firebase.getCurrentUser()
        const coachListRef = fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1)
        const coachListSnap = await coachListRef.get()
        if (!coachListSnap.empty) {
            let course = coachListSnap.docs[0].data()
            fb.coachesCollection.where("user_id", "==", course.coach.user_id).limit(1).get()
                .then((querySnapshot) => {
                    state.userProgramCoachDetail = querySnapshot.docs.map(doc => doc.data())
                })
        }
    }),
    getAdminProgramCoachDetail(context, payload) {
        fb.coachListCollection.doc(payload.coachId)
            .onSnapshot((querySnapshot) => {
                var coachArray = querySnapshot.data()
                coachArray.id = querySnapshot.id
                state.adminCoachDetails = coachArray
                fb.coachesCollection.where("user_id", "==", coachArray.coach.user_id).limit(1).get()
                    .then((querySnapshot) => {
                        state.programCoachDetail = querySnapshot.docs.map(doc => doc.data())
                    })
            })
    },
    getProgramCoachList: firestoreAction(async (context, payload) => {
        fb.coachesCollection.where("online", "==", true)
            .orderBy("user.lastname")
            .orderBy("user.firstname")
            .get()
            .then((querySnapshot) => {
                const coachArray = querySnapshot.docs.map(doc => doc.data())
                context.commit('SET_COACHES_BATCH', { coachArray })
            })
    }),
    getProgramSubscriptionList: firestoreAction(async (context, payload) => {
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).where("online", "==", true).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                querySnapshot.docs.map(doc => {
                    let subscriberlist = fb.coachSubscribe.where("coach_list_id", "==", doc.id)
                        .orderBy("user.firstname")
                    if (context.state.loadMoreLastSubscribers != null) {
                        subscriberlist = subscriberlist.startAfter(context.state.loadMoreLastSubscribers)
                    }
                    subscriberlist.limit(payload.limit || 20)
                        .get()
                        .then((subscriber) => {
                            const subscriberArray = subscriber.docs.map(doc => doc.data())
                            if (subscriberArray.length > 0) {
                                let lastVisible = subscriber.docs[subscriber.docs.length - 1]
                                context.commit('MERGE_SUBSCRIBERS_BATCH', { subscriberArray })
                                context.commit('SET_LOADMORE_LAST_SUBSCRIBERS', { lastVisible })
                            }
                            if (subscriberArray.length < payload.limit) context.commit('ALL_SUBSCRIBERS_LOADED')
                        })
                })
            })
            .catch((error) => { })
    }),
    getLatestProgramSubscriptionList: firestoreAction(async (context, payload) => {
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).where("online", "==", true).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                querySnapshot.docs.map(doc => {
                    let subscriberlist = fb.coachSubscribe.where("coach_list_id", "==", doc.id)
                        .orderBy("user.firstname")
                        .limit(5)
                        .get()
                        .then((subscriber) => {
                            const subscriberArray = subscriber.docs.map(doc => doc.data())
                            if (subscriberArray.length > 0) {
                                context.commit('SET_LATEST_SUBSCRIBERS_BATCH', { subscriberArray })
                            }
                        })
                })
            })
            .catch((error) => { })
    }),
    async getProgramSessions(context) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions")
                    .where("coachVisible", "==", true)
                    // .where("online", "==", true)
                    .orderBy("datetime", "desc")
                    .get()
                    .then((querySnapshot) => {
                        let sessions = []
                        querySnapshot.forEach((doc) => {
                            let subSessionsArray = []
                            subSessionsArray = doc.data()
                            subSessionsArray['id'] = doc.id
                            sessions.push(subSessionsArray)
                        })
                        state.programSessions = sessions
                    })
            })
    },
    async getAdminCoachList(context, payload) {
        fb.coachListCollection
            .orderBy("coach.last")
            .orderBy("coach.first")
            .onSnapshot((querySnapshot) => {
                var coaches = []
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    coaches.push(data)
                })
                state.adminCoachList = coaches
            })
    },
    async getSessionDetail(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions").doc(payload.sessionId)
                    .onSnapshot((sessionDoc) => {
                        let sessionData = sessionDoc.data()
                        state.programVideo = { smallthumbnail: sessionData.coach.largeImage, video: sessionData.videourl }
                        state.programSessionDetail = sessionData

                    })

            })
    },
    async getAdminSessionDetail(context, payload) {
        fb.coachListCollection.doc(payload.coachlistid).collection("sessions").doc(payload.sessionId)
            .onSnapshot((sessionDoc) => {
                let sessionData = sessionDoc.data()
                state.programVideo = { smallthumbnail: sessionData.coach.largeImage, video: sessionData.videourl }
                state.programSessionDetail = sessionData

            })
    },
    getProgramUserSubscriptions: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('programSubscriberList', fb.coachSubscribe.where("user.user_id", "==", payload.userId))
    }),
    getProgramSubscriberDetail: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('programSubscriberDetail', fb.usersCollection.doc(payload.uid))
    }),
    getProgramContactDetails: firestoreAction(async (context) => {
        var user = await fb.firebase.getCurrentUser()
        return context.bindFirestoreRef('programCoachDetail', fb.coachesCollection.where("user_id", "==", user.uid).limit(1))
    }),
    async getChatHistory(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions").doc(payload.sessionId).collection("session_chat")
                    .where("online", "==", true)
                    .orderBy("datetime", "desc")
                    .get()
                    .then((querySnapshot) => {
                        let chatArray = []
                        querySnapshot.forEach((doc) => {
                            chatArray.push(doc.data());
                        })
                        state.chatHistory = chatArray
                    })
            })
    },
    async getAdminChatHistory(context, payload) {
        fb.coachListCollection.doc(payload.coachlistid).collection("sessions").doc(payload.sessionId).collection("session_chat")
            .where("online", "==", true)
            .orderBy("datetime", "desc")
            .get()
            .then((querySnapshot) => {
                let chatArray = []
                querySnapshot.forEach((doc) => {
                    chatArray.push(doc.data());
                })
                state.chatHistory = chatArray
            })
    },
    async getVideoIssues(context, payload) {
        let sessionid = payload.sessionId;
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid)
            .onSnapshot((doc) => {
                let coachData = doc.data()
                fb.coachListCollection
                    .doc(coachData.coach_list[0].coach_list_id)
                    .collection("sessions")
                    .doc(sessionid)
                    .collection("reports")
                    .orderBy("datetime", "desc")
                    .get()
                    .then((querySnapshot) => {
                        let issueArray = []
                        querySnapshot.forEach((doc) => {
                            issueArray.push(doc.data())
                        })
                        state.videoIssues = issueArray
                    })
            })

    },
    async getAdminVideoIssues(context, payload) {
        let sessionid = payload.sessionId;
        let coachlistid = payload.coachlistid;
        fb.coachListCollection
            .doc(coachlistid)
            .collection("sessions")
            .doc(sessionid)
            .collection("reports")
            .orderBy("datetime", "desc")
            .get()
            .then((querySnapshot) => {
                let issueArray = []
                querySnapshot.forEach((doc) => {
                    issueArray.push(doc.data())
                })
                state.videoIssues = issueArray
            })

    },
    async updateIssuesCount(context, payload) {
        let sessionid = payload.sessionId;
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid)
            .onSnapshot((doc) => {
                let coachData = doc.data()
                fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions").doc(sessionid)
                    .onSnapshot((sessionDoc) => {
                        let sessionData = sessionDoc.data()
                        if (sessionData.reportCount) {
                            state.issuesCount = sessionData.reportCount
                        } else {
                            state.issuesCount = 0
                        }
                    })
            })
    },
    async updateAdminIssuesCount(context, payload) {
        let sessionid = payload.sessionId;
        let coachlistid = payload.coachlistid;
        fb.coachListCollection.doc(coachlistid).collection("sessions").doc(sessionid)
            .onSnapshot((sessionDoc) => {
                let sessionData = sessionDoc.data()
                if (sessionData.reportCount) {
                    state.issuesCount = sessionData.reportCount
                } else {
                    state.issuesCount = 0
                }
            })
    },
    async addSessionDetail(context, payload) {
        let title = payload.title;
        let sessionid = payload.sessionId;
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                let coachArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    coachArray.push(data)
                })
                fb.coachListCollection.doc(coachArray[0].id).collection("sessions").doc(sessionid).update({
                    title: title
                })
                fb.feedCollection.where("sessionid", "==", sessionid).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.update({
                            title: title
                        })
                    })
                })
                .catch((error) => {
                    // console.log(error)
                })
            })
            .catch((error) => {
                // The document probably doesn't exist.
                // console.error("Error updating document: ", error);
            });

    },
    async addAdminSessionDetail(context, payload) {
        let title = payload.title;
        let sessionid = payload.sessionId;
        let coachlistid = payload.coachlistid;
        fb.coachListCollection.doc(coachlistid).collection("sessions").doc(sessionid).update({
            title: title
        })
        fb.feedCollection.where("sessionid", "==", sessionid).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.update({
                            title: title
                        })
                    })
                })
                .catch((error) => {
                    // console.log(error)
                })
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}