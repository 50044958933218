import * as fb from '../../../api/firebase.js';
import { firestoreAction } from 'vuexfire'

const state = {
    loadMoreFeed: [],
    loadMoreAllFeed: [],
    coachFeed: [],
    allFeed: [],
    individualFeed: [],
    moreAllFeedPending: true,
    moreAllAllFeedPending: true,
    loadMoreLastAllFeedComments: null,
    feedAllComments: [],
    feedAllReplies: [],
    moreAllNotificationsDashboardFeedPending: true,
}

const getters = {
    loadMoreFeed: state => state.loadMoreFeed,
    loadMoreAllFeed: state => state.loadMoreAllFeed,
    coachFeed: state => state.coachFeed,
    allFeed: state => state.allFeed,
    individualFeed: state => state.individualFeed,
    moreAllFeedPending: state => state.moreAllFeedPending,
    moreAllAllFeedPending: state => state.moreAllAllFeedPending,
    feedAllComments: state => state.feedAllComments,
    feedAllReplies: state => state.feedAllReplies,
    moreAllNotificationsDashboardFeedPending: state => state.moreAllNotificationsDashboardFeedPending,
}

const mutations = {
    MERGE_ALL_FEED_BATCH(state, payload) {
        state.coachFeed = state.coachFeed.concat(payload.feedArray)
    },
    MERGE_ALL_ALL_FEED_BATCH(state, payload) {
        state.allFeed = state.allFeed.concat(payload.feedArray)
    },
    SET_LOADMOREFEED_ALL(state, payload) {
        state.loadMoreFeed = payload.lastVisible
    },
    SET_LOADMOREFEED_ALL_ALL(state, payload) {
        state.loadMoreAllFeed = payload.lastVisible
    },
    ALL_FEED_LOADED(state) {
        state.moreAllFeedPending = false
    },
    ALL_ALL_FEED_LOADED(state) {
        state.moreAllAllFeedPending = false
    },
    RESET_ALL_FEED(state) {
        state.coachFeed = []
        state.loadMoreFeed = null
        state.moreAllFeedPending = true
    },
    RESET_ALL_ALL_FEED(state) {
        state.allFeed = []
        state.loadMoreAllFeed = null
        state.moreAllAllFeedPending = true
    },
    SET_INDIVIDUAL_FEED(state, payload) {
        state.individualFeed = payload.feedArray
    },
    MERGE_ALL_NOTIFICATIONS_FEED_COMMENTS(state, payload) {
        state.feedAllComments = state.feedAllComments.concat(payload.notifyArray)
    },
    MERGE_ALL_NOTIFICATIONS_FEED_REPLIES(state, payload) {
        state.feedAllReplies = state.feedAllReplies.concat(payload.notifyArray)
    },
    SET_LOADMORE_LAST_ALL_FEEDCOMMENTS(state, payload) {
        state.loadMoreLastAllFeedComments = payload.lastVisible
    },
    ALL_NOTIFICATIONSDASHBOARD_LOADED_FEED(state) {
        state.moreAllNotificationsDashboardFeedPending = false
    },
    RESET_ALL_DASHBOARDFEED_MESSAGES(state) {
        state.loadMoreLastAllFeedComments = null
        state.feedAllComments = []
        state.moreAllNotificationsDashboardFeedPending = true
    },
}

const actions = {
    async getFeedPagination(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                let coachArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    coachArray.push(data)
                })
                let coachListId = coachArray[0].id;
                let feedList = fb.feedCollection
                    .where("coachlistid", "==", coachListId)
                    .orderBy("datetime", "desc")
                if (context.state.loadMoreFeed != null) {
                    feedList = feedList.startAfter(context.state.loadMoreFeed)
                }
                feedList.limit(payload.limit || 10)
                    .get()
                    .then((querySnapshot) => {
                        let feedArray = []
                        querySnapshot.forEach((doc) => {
                            let subSessionsFeed = []
                            subSessionsFeed = doc.data()
                            subSessionsFeed['id'] = doc.id
                            feedArray.push(subSessionsFeed)
                        })
                        if (feedArray.length > 0) {
                            let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
                            context.commit('MERGE_ALL_FEED_BATCH', { feedArray })
                            context.commit('SET_LOADMOREFEED_ALL', { lastVisible })
                        }
                        if (feedArray.length < payload.limit) context.commit('ALL_FEED_LOADED')
                    })
            })
    },
    resetAllFeed(context) {
        context.commit("RESET_ALL_FEED")
        context.dispatch("getFeedPagination", {
            limit: 20
        })
    },
    async getAllFeedPagination(context, payload) {
        let feedList = fb.feedCollection
            .where("isTest", "==", false)
            .orderBy("datetime", "desc")
        if (context.state.loadMoreAllFeed != null) {
            feedList = feedList.startAfter(context.state.loadMoreAllFeed)
        }
        feedList.limit(payload.limit || 20)
            .get()
            .then((querySnapshot) => {
                let feedArray = []
                querySnapshot.forEach((doc) => {
                    let subSessionsFeed = []
                    subSessionsFeed = doc.data()
                    subSessionsFeed['id'] = doc.id
                    feedArray.push(subSessionsFeed)
                })
                if (feedArray.length > 0) {
                    let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
                    context.commit('MERGE_ALL_ALL_FEED_BATCH', { feedArray })
                    context.commit('SET_LOADMOREFEED_ALL_ALL', { lastVisible })
                }
                if (feedArray.length < payload.limit) context.commit('ALL_ALL_FEED_LOADED')
            })
    },
    resetAllAllFeed(context) {
        context.commit("RESET_ALL_ALL_FEED")
        context.dispatch("getAllFeedPagination", {
            limit: 20
        })
    },
    getFeedById(context, payload) {
        fb.feedCollection.doc(payload.feedid)
            .get().then((doc) => {
                let feedArray = []
                if (doc.exists) {
                    let subSessionsFeed = []
                    subSessionsFeed = doc.data()
                    subSessionsFeed['id'] = doc.id
                    feedArray.push(subSessionsFeed)
                }
                context.commit('SET_INDIVIDUAL_FEED', { feedArray })
            })
    },
    async feedGetAllComments(context, payload) {
        fb.feedcommentsCollection.where("coach_list_id", "==", payload.coachlistid).where("feedid", "==", payload.feedid).where("online", "==", true)
                    .orderBy("datetime", "asc")
                    .get()
                    .then((querySnapshot) => {
                        let notifyArray = []
                        querySnapshot.forEach((doc) => {
                            const data = doc.data()
                            data.id = doc.id
                            data.replies = []
                            notifyArray.push(data)
                        })
                        // const notifyArray = querySnapshot.docs.map(doc => doc.data())
                        if (notifyArray.length > 0) {
                            context.commit('MERGE_ALL_NOTIFICATIONS_FEED_COMMENTS', { notifyArray })
                        }
                        if (notifyArray.length < payload.limit) {
                            context.commit('ALL_NOTIFICATIONSDASHBOARD_LOADED_FEED')
                        }
                    })
    },
    async feedGetReplies(context, payload) {
        fb.feedreplyCollection.where("commentid", "==", payload.commentid).where("online", "==", true)
            .orderBy("datetime", "asc")
            .get()
            .then((querySnapshot) => {
                let notifyArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    notifyArray.push(data)
                })
                // const notifyArray = querySnapshot.docs.map(doc => doc.data())
                if (notifyArray.length > 0) {
                    context.commit('MERGE_ALL_NOTIFICATIONS_FEED_REPLIES', { notifyArray })
                }
            })
            .catch((error) => {
                // console.log(error)
            })
    },
    async addCommentToFeed(context, payload) {
        let feedid = payload.feedid
        let newpost = payload.post
        let user = await fb.firebase.getCurrentUser()
        fb.feedCollection.doc(feedid).get().then((feeddicuss) => {
            let feeddis = feeddicuss.data()
            fb.usersCollection.doc(user.uid).get().then((doc) => {
                let data = doc.data()
                fb.feedCollection.doc(feedid).collection("feedcomments").add({
                    coach_list_id: feeddis.coachlistid,
                    datetime: fb.firebase.firestore.FieldValue.serverTimestamp(),
                    feedid: feedid,
                    likeCount: 0,
                    online: true,
                    replyCount: 0,
                    text: newpost,
                    user: {
                        firstname: data.firstname,
                        profileimage: data.profileimage,
                        user_id: data.user_id
                    }
                })
            })
        }).then((docRef) => {
            context.commit('RESET_ALL_DASHBOARDFEED_MESSAGES')
            context.dispatch("feedGetAllComments", {
                feedid: feedid,
            });
        })
    },
    deleteFeedComment(context, payload) {
        var deleteComment = fb.functions.httpsCallable('deleteadminfeedcomment');
        deleteComment({ feedid: payload.feedid, commentid: payload.commentId })
            .catch((error) => { })
    },
    async addNewReplyToFeed(context, payload) {
        let feedid = payload.feedid
        let feedcommentid = payload.feedcommentid
        let reply = payload.text
        let user = await fb.firebase.getCurrentUser()
        fb.feedCollection.doc(feedid).get().then((feeddicuss) => {
            let feeddis = feeddicuss.data()

            fb.usersCollection.doc(user.uid).get().then((doc) => {
                let data = doc.data()
                fb.feedCollection
                    .doc(feedid)
                    .collection("feedcomments")
                    .doc(feedcommentid)
                    .collection("feedreplies")
                    .add({
                        coach_list_id: feeddis.coachlistid,
                        commentid: feedcommentid,
                        datetime: fb.firebase.firestore.FieldValue.serverTimestamp(),
                        feedid: feedid,
                        likeCount: 0,
                        online: true,
                        text: reply,
                        user: {
                            firstname: data.firstname,
                            profileimage: data.profileimage,
                            user_id: data.user_id
                        }
                    })
            })
        })
    },
    deleteFeedReply(context, payload) {
        var changeCount = fb.functions.httpsCallable('deleteadminfeedreply');
        changeCount({ feedid: payload.feedid, commentid: payload.commentId, replyid: payload.replyId })
            .catch((error) => { })
    },
}

export default {
    state,
    mutations,
    getters,
    actions
}