import Vue from "vue";
import App from "./App.vue";
import store from "@/core/services/store";
import router from "./router";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueMeta from 'vue-meta'
import excel from 'vue-excel-export'

Vue.use(excel)

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Remove this to disable mock API
MockService.init();

import * as fb from './api/firebase.js';
Vue.prototype.$messaging = fb.messaging

Vue.prototype.$pages = {
  Messageboard: Symbol("messageboard"),
  Video: Symbol("video"),
  Notification: Symbol("notification"),
  Comments: Symbol("comments")
}

import VueMixpanelBrowser from 'vue-mixpanel-browser';
Vue.use(VueMixpanelBrowser, {
  token: "800e02b4e1962021aaf2e6989b36e915",
  config: { debug: true }
});

// navigator.serviceWorker.register('/firebase-messaging-sw.js')
//   .then((registration) => {
//     Vue.prototype.$messaging.useServiceWorker(registration)
//   }).catch(err => {
//     console.log(err)
//   })

Vue.use(VueMeta)

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: {
    "italic": true,
    "underline": true,
    "headings": true,
    "unorderedList": true,
    "separator":true,
    "removeFormat": true,
    "image": true,
    "code": true,
    "link": true,
    "table": true,
    "justifyLeft": true,
    "justifyCenter": true,
    "justifyRight": true,
    "orderedList": true
  },
  forcePlainTextOnPaste: true
}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

import VueTimeago from 'vue-timeago'

import 'vue-event-calendar/dist/style.css' //^1.1.10, CSS has been extracted as one file, so you can easily update it.
import vueEventCalendar from 'vue-event-calendar'
Vue.use(vueEventCalendar, {locale: 'en'}) 

import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';

Vue.component('VueToggles', VueToggles);

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");