import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import router from "@/router";
import { data } from 'jquery';

var pageArray = {
    Messageboard: Symbol("messageboard"),
    Video: Symbol("video"),
    Notification: Symbol("notification"),
    Comments: Symbol("comments"),
    Empty: Symbol("empty")
}

const state = {
    dashboardProgramSessions: [],
    loadMoreSessions: [],
    moreAllSessionsPending: true,
    currentSession: null,
    dashboardMessages: [],
    loadMoreMessages: [],
    allTopics: [],
    allTopicsForSession: [],
    allTopicsLoaded: false,
    moreMessagesPending: true,
    messagesResetLoading: false,
    topicId: null,
    pages: pageArray,
    currentPage: pageArray.Messageboard,
    sessionDiscussionId: null,
    commentDiscussionId: null,
    commentCommentId: null,
    dashboardNotifcationDetail: null,
    loadMoreLastAllDashboardTopic: null,
    dashboardAllNotificationsByTopic: [],
    moreAllNotificationsDashboardTopicPending: true,
    scrollToId: null,
    coachNotifications: [],
    newNotification: false,
    allMessagesLoaded: false

}

const getters = {
    dashboardProgramSessions: state => {
        return state.dashboardProgramSessions
    },
    loadMoreSessions: state => state.loadMoreSessions,
    moreAllSessionsPending: state => state.moreAllSessionsPending,
    currentSession: state => state.currentSession,
    dashboardMessages: state => state.dashboardMessages,
    loadMoreMessages: state => state.loadMoreMessages,
    moreMessagesPending: state => state.moreMessagesPending,
    allTopics: state => state.allTopics,
    allTopicsLoaded: state => state.allTopicsLoaded,
    messagesResetLoading: state => state.messagesResetLoading,
    allTopicsForSession: state => state.allTopicsForSession,
    topicId: state => state.topicId,
    currentPage: state => state.currentPage,
    pages: state => state.pages,
    sessionDiscussionId: state => state.sessionDiscussionId,
    commentDiscussionId: state => state.commentDiscussionId,
    commentCommentId: state => state.commentCommentId,
    dashboardNotifcationDetail: state => state.dashboardNotifcationDetail,
    dashboardAllNotificationsByTopic: state => state.dashboardAllNotificationsByTopic,
    moreAllNotificationsDashboardTopicPending: state => state.moreAllNotificationsDashboardTopicPending,
    scrollToId: state => state.scrollToId,
    coachNotifications: state => state.coachNotifications,
    newNotification: state => state.newNotification,
    allMessagesLoaded: state => state.allMessagesLoaded
}

const mutations = {
    SetProgramSessions(state, payload) {
        state.dashboardProgramSessions = payload.sessionsArray
    },
    MERGE_ALL_SESSIONS_BATCH(state, payload) {
        state.dashboardProgramSessions = state.dashboardProgramSessions.concat(payload.sessionsArray)
    },
    SET_LOADMORESESSIONS_ALL(state, payload) {
        state.loadMoreSessions = payload.lastVisible
    },
    ALL_SESSIONS_LOADED(state) {
        state.moreAllSessionsPending = false
    },
    setCurrentSession(state, payload) {
        state.currentSession = payload.currentSession
    },
    updateTitle(state, payload) {
        if (state.currentSession) {
            state.currentSession.title = payload.title
        }
    },
    RESET_ALL_SESSIONS(state) {
        state.dashboardProgramSessions = []
        state.loadMoreSessions = null
        state.moreAllSessionsPending = true
    },
    RESET_ALL_MESSAGES(state) {
        state.messagesResetLoading = true
        state.allTopics = []
        state.allTopicsForSession = []
        state.dashboardMessages = []
        state.loadMoreMessages = []
        state.allTopicsLoaded = false
        state.moreMessagesPending = true
        state.topicId = null
        state.allMessagesLoaded = false
    },
    REFRESH_MESSAGES(state) {
        state.messagesResetLoading = true
        state.allTopics = []
        state.allTopicsForSession = []
        state.dashboardMessages = []
        state.loadMoreMessages = []
        state.allTopicsLoaded = false
        state.moreMessagesPending = true
    },
    setAllTopics(state, payload) {
        state.allTopics = payload.allTopics
    },
    setAllTopicsForSession(state, payload) {
        state.allTopicsForSession = payload.allTopicsForSession
    },
    setTopicId(state, payload) {
        state.topicId = payload.topicid
    },
    setDashboardMessages(state, payload) {
        state.dashboardMessages = state.dashboardMessages.concat(payload.messageArray)
    },
    setLoadMoreMessages(state, payload) {
        state.loadMoreMessages = payload.lastVisible
    },
    ALL_MESSAGES_LOADED(state) {
        state.loadMoreMessages = false
    },
    ALL_TOPICS_LOADED(state) {
        state.allTopicsLoaded = true
    },
    setMessageLoading(state) {
        state.messagesResetLoading = false
    },
    setCurrentPage(state, payload) {
        if (payload.currentpage == state.pages.Comments) {
            state.currentPage = state.pages.Empty
            state.currentPage = payload.currentpage
        } else {
            state.currentPage = payload.currentpage
        }
    },
    setSessionDiscussionId(state, payload) {
        state.sessionDiscussionId = payload.sessionDiscussionId
    },
    setCommentDiscussionId(state, payload) {
        state.commentDiscussionId = payload.discussionid
    },
    setCommentCommentId(state, payload) {
        state.commentCommentId = payload.commentid
    },
    setDashboardNotificationDetail(state, payload) {
        state.dashboardNotifcationDetail = payload.notifydetail
    },
    RESET_ALL_DASHBOARDTOPIC_MESSAGES(state) {
        state.loadMoreLastAllDashboardTopic = null
        state.dashboardAllNotificationsByTopic = []
        state.moreAllNotificationsDashboardTopicPending = true
    },
    SET_LOADMORE_LAST_ALL_DASHBOARDTOPIC(state, payload) {
        state.loadMoreLastAllDashboardTopic = payload.lastVisible
    },
    MERGE_ALL_NOTIFICATIONS_DASHBOARDBATCH_TOPIC(state, payload) {
        state.dashboardAllNotificationsByTopic = state.dashboardAllNotificationsByTopic.concat(payload.notifyArray)
    },
    ALL_NOTIFICATIONSDASHBOARD_LOADED_TOPIC(state) {
        state.moreAllNotificationsDashboardTopicPending = false
    },
    setScrollId(state, payload) {
        state.scrollToId = payload.scrollToId
    },
    MERGE_COACH_NOTIFICATIONS(state, payload) {
        let found = state.coachNotifications.some(el => el.id === payload.notifications.id)
        if (!found) {
            state.coachNotifications = state.coachNotifications.concat(payload.notifications)
        }
        state.coachNotifications.sort((a, b) => (new Date(a.datetime.seconds * 1000) < new Date(b.datetime.seconds * 1000)) ? 1 : ((new Date(b.datetime.seconds * 1000) < new Date(a.datetime.seconds * 1000)) ? -1 : 0))
    },
    DELETE_COACH_NOTIFICATION(state, payload) {
        const index = state.coachNotifications.findIndex(object => {
            return object.id === payload.notifications.id
        })
        if (index >= 0) {
            state.coachNotifications.splice(index, 1)
        }
    },
    changeNewNotification(state, payload) {
        state.newNotification = payload.notify
    },
    setdashboardNotifcationDetailTitle(state, payload) {
        state.dashboardNotifcationDetail.title = payload.title
        state.dashboardNotifcationDetail.text = payload.text
    },
    setAllMessagesLoaded(state) {
        state.allMessagesLoaded = true
    }
}

const actions = {
    setCurrentSession(context, payload) {
        context.commit('setCurrentSession', {
            currentSession: payload.currentSession
        })
    },
    async getCurrentSession(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                let sessionList = fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions").doc(payload.sessionid)
                sessionList.get().then((doc) => {
                    if (doc.exists) {
                        let data = doc.data();
                        context.commit("setCurrentSession", {
                            currentSession: data
                        })
                    }
                })
            })
    },
    resetAllSessions(context) {
        context.commit("RESET_ALL_SESSIONS")
        context.dispatch("getDashboardProgramSessions", {
            limit: 10
        })
    },
    async getDashboardProgramSessions(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                let sessionList = fb.coachListCollection.doc(coachData.coach_list[0].coach_list_id).collection("sessions")
                    .where("coachVisible", "==", true)
                    // .where("online", "==", true)
                    .orderBy("datetime", "desc")
                if (context.state.loadMoreSessions != null) {
                    sessionList = sessionList.startAfter(context.state.loadMoreSessions)
                }
                sessionList.limit(payload.limit || 10)
                    .get()
                    .then((querySnapshot) => {
                        let sessionsArray = []
                        querySnapshot.forEach((doc) => {
                            let subSessionsArray = []
                            subSessionsArray = doc.data()
                            subSessionsArray['id'] = doc.id
                            sessionsArray.push(subSessionsArray)
                        })
                        if (sessionsArray.length > 0) {
                            let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
                            context.commit('MERGE_ALL_SESSIONS_BATCH', { sessionsArray })
                            context.commit('SET_LOADMORESESSIONS_ALL', { lastVisible })
                        }
                        if (sessionsArray.length < payload.limit) context.commit('ALL_SESSIONS_LOADED')
                    })
            })
    },
    async getAllDashboardProgramSessions(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachesCollection.doc(user.uid).get()
            .then((doc) => {
                let coachData = doc.data()
                fb.feedCollection
                .where("coachlistid", "==", coachData.coach_list[0].coach_list_id)
                .where("type", "==", "session")
                .orderBy("datetime", "desc")
                .get()
                .then((querySnapshot) => {
                    let sessionsArray = []
                    querySnapshot.forEach((doc) => {
                        let subSessionsArray = []
                        subSessionsArray = doc.data()
                        subSessionsArray['id'] = doc.id
                        sessionsArray.push(subSessionsArray)
                    })
                    context.commit('SetProgramSessions', { sessionsArray })
                })
            })
    },
    async getDashboardProgramSessionsNoId(context, payload) {
        fb.feedCollection
                .where("type", "==", "session")
                .orderBy("datetime", "desc")
                .limit(100)
                .get()
                .then((querySnapshot) => {
                    let sessionsArray = []
                    querySnapshot.forEach((doc) => {
                        let subSessionsArray = []
                        subSessionsArray = doc.data()
                        subSessionsArray['id'] = doc.id
                        sessionsArray.push(subSessionsArray)
                    })
                    context.commit('SetProgramSessions', { sessionsArray })
                })
    },
    async dashboardGetNotifications(context) {
        let user = await fb.firebase.getCurrentUser()
        fb.userNotifications
            .where("touser.user_id", "==", user.uid)
            .where("touser.isCoach", "==", true)
            .orderBy("datetime", "desc")
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const data = change.doc.data()
                    if (change.type === "added") {
                        data.id = change.doc.id
                        context.commit("MERGE_COACH_NOTIFICATIONS", {
                            notifications: data
                        })
                        context.commit("changeNewNotification", {
                            notify: true
                        })
                    }
                    if (change.type === "removed") {
                        data.id = change.doc.id
                        context.commit("DELETE_COACH_NOTIFICATION", {
                            notifications: data
                        })
                    }
                })
            })
    },
}

export default {
    state,
    mutations,
    getters,
    actions
}
