import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    tabIndex: 0,
    activeTab: 0,
}

const getters = {
    tabIndex: state => state.tabIndex,
    activeTab: state => state.activeTab
}

const mutations = {
    CHANGE_TABINDEX(state, payload) {
        state.tabIndex = payload.tabIndex
    },
    CHANGE_ACTIVETAB(state, payload) {
        state.activeTab = payload.activeTab
    }
}

export default {
    state,
    getters,
    mutations
}
