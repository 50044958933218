import * as fb from '../../../api/firebase.js';

const state = {
    recordingArn: null,
    recordingActive: null,
    livestreamActive: null,
    arn: null,
    streamKeyArn: null,
    ingestEndpoint: null,
    ingestServer: null,
    playbackUrl: null,
    streamKey: null,
    coachListId: null,
    sessionId: null,
    recordingCount: 20,
    channelTimeout: false,
    livestreamOnline: [],
    livestreamNow: [],
    livestreamTest: []
}

const getters = {
    ingestServer: state => state.ingestServer,
    playbackUrl: state => state.playbackUrl,
    streamKey: state => state.streamKey,
    arn: state => state.arn,
    streamKeyArn: state => state.streamKeyArn,
    recordingActive: state => state.recordingActive,
    livestreamActive: state => state.livestreamActive,
    recordingCount: state => state.recordingCount,
    channelTimeout: state => state.channelTimeout,
    sessionId: state => state.sessionId,
    coachListId: state => state.coachListId,
    livestreamOnline: state => state.livestreamOnline,
    livestreamNow: state => state.livestreamNow,
    livestreamTest: state => state.livestreamTest
}

const mutations = {
    RESET_RECORDINGARN(state) {
        state.recordingArn = null
    },
    RESET_RECORDINGACTIVE(state) {
        state.recordingActive = null
    },
    RESET_LIVESTREAMACTIVE(state) {
        state.livestreamActive = null
    },
    RESET_ARN(state) {
        state.arn = null
    },
    RESET_STREAMKEYARN(state) {
        state.streamKeyArn = null
    },
    RESET_INGESTENDPOINT(state) {
        state.ingestEndpoint = null
    },
    RESET_INGESTSERVER(state) {
        state.ingestServer = null
    },
    RESET_PLAYBACKURL(state) {
        state.playbackUrl = null
    },
    RESET_STREAMKEY(state) {
        state.streamKey = null
    },
    RESET_COACHLISTID(state) {
        state.coachListId = null
    },
    RESET_SESSIONID(state) {
        state.sessionId = null
    }
}

const timer = ms => new Promise(res => setTimeout(res, ms))

const actions = {
    listRecordingConfigurations(context) {
        return new Promise((resolve, reject) => {
            var params = {};
            fb.ivs.listRecordingConfigurations(params, function (err, data) {
                if (err) {
                    // console.log(err, err.stack)
                    reject(err)
                } else {
                    // data.recordingConfigurations.forEach(element => {
                    //     console.log('recording arn')
                    //     console.log(element.arn)
                    // });
                    resolve(data.recordingConfigurations.length)
                }
            })
        })
    },
    liveUpateRecordingConfigurations(context) {
        fb.livestreamRecording.where("isLive", "==", true)
            .onSnapshot((querySnapshot) => {
                state.recordingCount = 20 - querySnapshot.docs.length
                var live = []
                querySnapshot.forEach((doc) => {
                        let data = doc.data()
                        live.push(data)
                })
                state.livestreamOnline = live
            })
    },
    liveUpateRecordingLiveConfigurations(context) {
        fb.livestreamRecording.where("isLive", "==", true).where("isTest", "==", false)
            .onSnapshot((querySnapshot) => {
                state.recordingCount = 20 - querySnapshot.docs.length
                var live = []
                querySnapshot.forEach((doc) => {
                        let data = doc.data()
                        live.push(data)
                })
                state.livestreamNow = live
            })
    },
    liveUpateRecordingTestConfigurations(context) {
        fb.livestreamRecording.where("isLive", "==", true).where("isTest", "==", true)
            .onSnapshot((querySnapshot) => {
                state.recordingCount = 20 - querySnapshot.docs.length
                var live = []
                querySnapshot.forEach((doc) => {
                        let data = doc.data()
                        live.push(data)
                })
                state.livestreamTest = live
            })
    },
    createRecordingConfiguration(context) {
        return new Promise((resolve, reject) => {
            let params = {
                destinationConfiguration: {
                    /* required */
                    s3: {
                        bucketName: "ingomu-streaming" /* required */,
                    },
                },
                name: Date.now().toString()
            };
            fb.ivs.createRecordingConfiguration(params, function (err, data) {
                if (err) {
                    // console.log(err)
                    reject(err)
                } else {
                    state.recordingArn = data.recordingConfiguration.arn
                    resolve()
                }
            })
        })
    },
    async checkRecordingArn(context) {
        var params = {
            arn: state.recordingArn
        };
        for (let attempt = 0; attempt < 120; ++attempt) {
            if (attempt > 0) {
                await timer(3000)
            }
            try {
                let arnconfig = await fb.ivs.getRecordingConfiguration(params).promise()
                let status = arnconfig.$response.data.recordingConfiguration.state
                if (status === "ACTIVE") {
                    state.recordingActive = "ACTIVE"
                    return;
                }
            } catch {
                // console.log("Error gettings record arn")
            }
        }
        throw new Error("Couldn't get active recording arn")
    },
    deleteRecordingConfiguration(context, deleteAll = false) {
        if (state.recordingArn !== null) {
            var params = {
                arn: state.recordingArn
            };
            fb.ivs.deleteRecordingConfiguration(params, function (err, data) {
                if (err) {
                    // console.log(err, err.stack); // an error occurred
                } else {
                    context.commit('RESET_RECORDINGARN')
                }
            });
        }
        if (deleteAll) {
            var params1 = {};
            fb.ivs.listRecordingConfigurations(params1, function (err, data) {
                if (err) {
                    // console.log(err, err.stack)
                } else {
                    data.recordingConfigurations.forEach(element => {
                        var params = {
                            arn: element.arn /* required */
                        };
                        fb.ivs.deleteRecordingConfiguration(params, function (err, data) {
                            if (err) {
                                // console.log(err, err.stack); // an error occurred
                            } else {
                                // console.log(data);
                            }
                        });
                    })
                }
            });
        }
    },
    listIVSChannels(context) {
        return new Promise((resolve, reject) => {
            var params = {};
            fb.ivs.listChannels(params, function (err, data) {
                if (err) {
                    reject(err)
                } else {
                    state.channelTimeout = false
                    // data.channels.forEach(element => {
                    //     console.log('channels')
                    //     console.log(element.arn)
                    // });
                    resolve(data.channels.length)
                }
            });
        })
    },
    async getIVSChannelInfo(context) {
        var params = {
            channelArn: state.arn
        }
        let count = 0;
        let countTry = 1799;
        for (let attempt = 0; attempt < 1800; attempt++) {
            if (attempt > 0) {
                // console.log(attempt)
                await timer(3000)
            }
            try {
                let streamInfo = await fb.ivs.getStream(params).promise()
                let status = streamInfo.stream.state
                if (status === "LIVE") {
                    state.livestreamActive = "LIVE"
                    var updateCreds = fb.functions.httpsCallable("updaterecordinglivev2");
                    updateCreds({
                        recordingArn: state.recordingArn,
                        coachlistid: state.coachListId
                    })
                    return;
                }
            } catch {
                // console.log("Error gettings livestream")
            }
            count++;
            if (count === countTry) {
                state.channelTimeout = true
            }
        }
        throw new Error("Couldn't get active livestream")
    },
    async getSessionId(context) {
        fb.livestreamRecording
            .where("arn", "==", state.arn)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let subData = doc.data()
                    state.sessionId = subData.sessionid
                })
            })
    },
    async createIVSChannel(context) {
        return new Promise(async (resolve, reject) => {
            let user = await fb.firebase.getCurrentUser()
            var params = {
                name: Date.now().toString(),
                latencyMode: "LOW"
            }
            if (state.recordingArn !== null) {
                params['recordingConfigurationArn'] = state.recordingArn
            }
            fb.ivs.createChannel(params, (err, data) => {
                if (err) {
                    // console.log(err, err.stack)
                    reject(err)
                } else {
                    state.arn = data.channel.arn;
                    state.ingestEndpoint = data.channel.ingestEndpoint;
                    state.ingestServer = 'rtmps://' + data.channel.ingestEndpoint;
                    state.playbackUrl = data.channel.playbackUrl;
                    state.streamKey = data.streamKey.value;
                    state.streamKeyArn = data.streamKey.arn;

                    fb.firebase.getCurrentUser().then((user) => {
                        fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1).get()
                            .then((querySnapshot) => {
                                let coachlistid = ""
                                let firstname = ""
                                let lastname = ""
                                let title = ""
                                let profileimage = ""
                                querySnapshot.forEach((doc) => {
                                    const data = doc.data()
                                    coachlistid = doc.id,
                                        firstname = data.coach.first
                                    lastname = data.coach.last
                                    title = data.title
                                    profileimage = data.coach.image
                                    var addCreds = fb.functions.httpsCallable("addlivestreamcredsv2");
                                    addCreds({
                                        arn: state.arn,
                                        streamKeyArn: state.streamKeyArn,
                                        recordingArn: state.recordingArn,
                                        user_id: user.uid,
                                        coach_list_id: coachlistid,
                                        firstname: firstname,
                                        lastname: lastname,
                                        profileimage: profileimage,
                                        title: title,
                                        playbackUrl: state.playbackUrl
                                    })
                                    state.coachListId = doc.id
                                    resolve()
                                })
                            })
                    })
                }
            })
        })
    },
    gcfDeleteIVSChannel(context) {
        if (state.arn !== null && state.statestreamarn !== null) {
            var deleteIVSinfo = fb.functions.httpsCallable("deletestreamcredsv2");
            deleteIVSinfo({ arn: state.arn, streamKeyArn: state.streamKeyArn, recordingArn: state.recordingArn });
        }
    },
    deleteIVSChannel(context, deleteAll = false) {
        return new Promise((resolve, reject) => {
            if (state.arn !== null && state.statestreamarn !== null) {
                var deleteIVSinfo = fb.functions.httpsCallable("deletestreamcredsv2");
                deleteIVSinfo({ arn: state.arn, streamKeyArn: state.streamKeyArn, recordingArn: state.recordingArn });
                context.commit('RESET_ARN')
                context.commit('RESET_INGESTENDPOINT')
                context.commit('RESET_INGESTSERVER')
                context.commit('RESET_PLAYBACKURL')
                context.commit('RESET_STREAMKEY')
                context.commit('RESET_RECORDINGARN')
                context.commit('RESET_RECORDINGACTIVE')
                context.commit('RESET_COACHLISTID')
            }
            if (deleteAll) {
                var params1 = {};
                fb.ivs.listChannels(params1, function (err, data) {
                    if (err) {
                        // console.log(err, err.stack)
                    } else {
                        data.channels.forEach(element => {
                            var params = {
                                arn: element.arn /* required */
                            };
                            fb.ivs.deleteChannel(params, function (err, data) {
                                if (err) {
                                    // console.log(err, err.stack); // an error occurred
                                } else {
                                    // console.log(data);
                                }
                            });
                        })
                    }
                    resolve()
                });
            }
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}