import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    onlineUsers: [],
    sessionChats: [],
    sessionChatsLive: [],
    sessionChatsTest: [],
    calendarDates: [],
    nextLivestream: [],
    adminCalendarDates: [],
    coachDates: [],
    adminCoachDates: [],
    courseInfo: [],
    likeCount: 0
}

const getters = {
    onlineUsers: state => state.onlineUsers,
    sessionChats: state => state.sessionChats,
    sessionChatsLive: state => state.sessionChatsLive,
    sessionChatsTest: state => state.sessionChatsTest,
    calendarDates: state => state.calendarDates,
    nextLivestream: state => state.nextLivestream,
    adminCalendarDates: state => state.adminCalendarDates,
    likeCount: state => state.likeCount,
    coachDates: state => state.coachDates,
    adminCoachDates: state => state.adminCoachDates,
    courseInfo: state => state.courseInfo
}

const mutations = {
    RESET_LIKECOUNT(state) {
        state.likeCount = 0
    },
    SET_COACHDATES(state, payload) {
        state.coachDates = payload.calArray
    },
    SET_ADMIN_COACHDATES(state, payload) {
        state.adminCoachDates = payload.calArray
    }
}

const actions = {
    getCoachLivestreamInfo(context) {
        return new Promise(async (resolve, reject) => {
            fb.firebase.getCurrentUser().then((user) => {
                fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1).get()
                    .then((querySnapshot) => {
                        var course = []
                        querySnapshot.forEach((doc) => {
                            let data = doc.data()
                            data.id = doc.id
                            course.push(data)
                        })
                        state.courseInfo = course
                    })
            })
        });
    },
    getCoachCalSchedule: firestoreAction((context, payload) => {
        var dateOffset = (24 * 60 * 60 * 1000) * 1; //1 day
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        return context.bindFirestoreRef('calendarDates',
            fb.calCollection
                .where("coach_list_id", "==", payload.coachListId)
                // .where("online", "==", true)
                // .where("isCanceled", "==", false)
                .orderBy("livestream_time", "asc")
                .startAt(todayStamp)
        )
    }),
    getNextLivestream: firestoreAction((context, payload) => {
        // Get time zone offset for NY, USA
        const getEstOffset = () => {
            const stdTimezoneOffset = () => {
                var jan = new Date(0, 1)
                var jul = new Date(6, 1)
                return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
            }
        
            var today = new Date()
            
            const isDstObserved = (today) => {
                return today.getTimezoneOffset() < stdTimezoneOffset()
            }
        
            if (isDstObserved(today)) {
                return 4
            } else {
                return 5
            }
        }
        var myDate = new Date();
        myDate.setHours(myDate.getHours() - getEstOffset())
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        return context.bindFirestoreRef('nextLivestream',
            fb.calCollection
                .where("livestream_time", ">=", todayStamp)
                .where("online", "==", true)
                .where("isCanceled", "==", false)
                .where("isTest", "==", false)
                .orderBy("livestream_time", "asc")
                .limit(1)
                // .startAt(todayStamp)
        )
    }),
    getAdminCoachCalSchedule(context, payload) {
        var dateOffset = (24 * 60 * 60 * 1000) * 1; //1 day
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        fb.calCollection
            .where("coach_list_id", "==", payload.coachListId)
            .orderBy("livestream_time", "asc")
            .startAt(todayStamp)
            .onSnapshot((querySnapshot) => {
                var calSched = []
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    calSched.push(data)
                })
                state.adminCalendarDates = calSched
            })
    },
    async getCoachDashboardSchedule(context, payload) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
        var dateOffset = (24 * 60 * 60 * 1000) * 1; //1 day
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        let user = await fb.firebase.getCurrentUser()
        fb.calCollection
            .where("coach.user_id", "==", user.uid)
            .where("online", "==", true)
            .orderBy("livestream_time", "asc")
            .startAt(todayStamp)
            .get()
            .then((querySnapshot) => {
                let calArray = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    let newdtt = new Date(data.livestream_time.seconds * 1000);
                    let newdttString = newdtt.toUTCString().toString();
                    let timeString = newdttString.substring(0, newdttString.length - 7);
                    const year = newdtt.getFullYear();
                    const month = newdtt.getMonth();
                    const day = newdtt.getDate();
                    const formattedDate = `${year}/${month + 1}/${day}`
                    let status = (data.isCanceled) ? "Canceled" : "Scheduled"
                    let customClass = (data.isCanceled) ? "canceled" : "scheduled"

                    let dictItem = {
                        date: formattedDate,
                        title: `${timeString} EST`,
                        desc: `${status}`,
                        customClass: `${customClass}`
                    }
                    calArray.push(dictItem)
                })

                context.commit("SET_COACHDATES", { calArray })
            })
    },
    async getAdminCoachDashboardSchedule(context, payload) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
        var dateOffset = (24 * 60 * 60 * 1000) * 1; //1 day
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        let user = await fb.firebase.getCurrentUser()
        fb.calCollection
            .where("online", "==", true)
            .orderBy("livestream_time", "asc")
            .startAt(todayStamp)
            .get()
            .then((querySnapshot) => {
                let calArray = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    let newdtt = new Date(data.livestream_time.seconds * 1000);
                    let newdttString = newdtt.toUTCString().toString();
                    let timeString = newdttString.substring(0, newdttString.length - 7);
                    const year = newdtt.getFullYear();
                    const month = newdtt.getMonth();
                    const day = newdtt.getDate();
                    const formattedDate = `${year}/${month + 1}/${day}`
                    let status = (data.isCanceled) ? "Canceled" : "Scheduled"
                    if (data.isTest) { status = "Canceled" }
                    let customClass = (data.isCanceled) ? "canceled" : "scheduled"

                    let dictItem = {
                        date: formattedDate,
                        title: `${timeString} EST`,
                        desc: `${data.coach.first} ${data.coach.last} - ${status}`,
                        customClass: `${customClass}`
                    }
                    calArray.push(dictItem)
                })

                context.commit("SET_ADMIN_COACHDATES", { calArray })
            })
    },
    async setUserOnline(context, payload) {
        var user = await fb.firebase.getCurrentUser()
        fb.usersCollection.doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                let data = doc.data()
                fb.coachListCollection
                    .doc(payload.coachListId)
                    .collection("sessions")
                    .doc(payload.sessionId)
                    .collection("session_users")
                    .doc(user.uid)
                    .set({
                        user: {
                            user_id: user.uid,
                            firstname: data.firstname,
                            profileimage: data.profileimage
                        },
                        online: true,
                        startdatetime: fb.firebase.firestore.FieldValue.serverTimestamp()
                    })
            }
        })
    },
    async setUserOffline(context, payload) {
        var user = await fb.firebase.getCurrentUser()
        if (payload.coachListId && payload.sessionId) {
            fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_users")
                .doc(user.uid)
                .update({
                    online: false,
                })
        }
    },
    getOnlineUsers: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('onlineUsers',
            fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_users")
                .where("online", "==", true)
                .orderBy("startdatetime", "desc"))
    }),
    getSessionChats: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('sessionChats',
            fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_chat")
                .where("online", "==", true)
                .orderBy("datetime", "asc")
        )
    }),
    getSessionChatsLive: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('sessionChatsLive',
            fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_chat")
                .where("online", "==", true)
                .orderBy("datetime", "asc")
        )
    }),
    getSessionChatsTest: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('sessionChatsTest',
            fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_chat")
                .where("online", "==", true)
                .orderBy("datetime", "asc")
        )
    }),
    async clearSessionChats(context, payload) {
        state.sessionChats = []
    },
    async clearSessionChatsLive(context, payload) {
        state.sessionChatsLive = []
    },
    async clearSessionChatsTest(context, payload) {
        state.sessionChatsTest = []
    },
    async changeMutedStatus(context, payload) {
        let coachListId = payload.coach_list_id;
        let sessionId = payload.sessionid;
        let muted = payload.isMuted;
        let userid = payload.userid;
        let firstname = payload.firstname;
        var muteString = "";
        if (muted) { muteString = "muted" } else { muteString = "unmuted" }
        fb.coachListCollection
            .doc(coachListId)
            .collection("sessions")
            .doc(sessionId)
            .collection("session_users")
            .doc(userid)
            .update({
                isMuted: muted
            })
        // fb.usersCollection.doc(userid).get().then((doc) => {
        //     let data = doc.data()
        //     fb.coachListCollection
        //         .doc(coachListId)
        //         .collection("sessions")
        //         .doc(sessionId)
        //         .collection("session_chat")
        //         .add({
        //             datetime: fb.firebase.firestore.FieldValue.serverTimestamp(),
        //             message: `${firstname} has been ${muteString}`,
        //             online: true,
        //             isCoach: true,
        //             user: {
        //                 firstname: data.firstname,
        //                 profileimage: data.profileimage,
        //                 user_id: data.user_id
        //             }
        //         })
        // });

    },
    async addNewMessage(context, payload) {
        let message = payload.text;
        let coachListId = payload.coach_list_id;
        let sessionId = payload.sessionid;

        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.doc(user.uid).get().then((doc) => {
            let data = doc.data()
            fb.coachListCollection
                .doc(coachListId)
                .collection("sessions")
                .doc(sessionId)
                .collection("session_chat")
                .add({
                    datetime: fb.firebase.firestore.FieldValue.serverTimestamp(),
                    message: message,
                    online: true,
                    isCoach: true,
                    user: {
                        firstname: data.firstname,
                        profileimage: data.profileimage,
                        user_id: data.user_id
                    }
                })
        })
    },
    getLikeCounts: firestoreAction((context, payload) => {
        fb.coachListCollection
            .doc(payload.coachListId)
            .collection("sessions")
            .doc(payload.sessionId)
            .onSnapshot((snapShot) => {
                if (snapShot.exists) {
                    let sessionData = snapShot.data();
                    state.likeCount = sessionData.likeCount
                }
            })

    })
}

export default {
    actions,
    state,
    getters,
    mutations
}