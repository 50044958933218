import * as fb from '../../../api/firebase.js';
import firebase from "firebase/app";

const actions = {
  updateContactDetails(context, form) {
    return new Promise(async (resolve, reject) => {
      var user = await fb.firebase.getCurrentUser()
      if (form.photo !== "" && form.photo !== null) {
        let firstPhoto = form.photo.replace('data:image/jpeg;base64,', '')
        let newPhoto = firstPhoto.replace('data:image/png;base64,', '')
        var ref = fb.storage.child('users/' + user.uid + '.jpg')
        ref.putString(newPhoto, 'base64').then(function (snapshot) {
          snapshot.ref.getDownloadURL().then((link) => {
            fb.usersCollection.doc(user.uid).update({
              profileimage: link
            })
          })
        })
      }
      fb.usersCollection.doc(user.uid).update({
        firstname: form.firstname,
      }).then(() => {
        fb.coachesCollection.doc(user.uid).update({
          "user.firstname": form.firstname,
          "user.lastname": form.lastname,
          phone: form.phone
        }).then(() => {
          user.updateProfile({
            displayName: form.firstname + " " + form.lastname
          }).then(() => {
            resolve()
          })
        })
      })
    })
  },
  updatePassword(context, form) {
    return new Promise(async (resolve, reject) => {
      var user = await fb.firebase.getCurrentUser()
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, form.oldpassword);
      user.reauthenticateWithCredential(credential).then(function () {
        user.updatePassword(form.newpassword).then(() => {
          var sendPasswordWarning = fb.functions.httpsCallable('passwordchangedsendemailv2');
          sendPasswordWarning({ email: user.email }).then((result) => {
            resolve()
          })
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  contactForgotPassword(context) {
    return new Promise(async (resolve, reject) => {
      var user = await fb.firebase.getCurrentUser()
      fb.auth.sendPasswordResetEmail(user.email).then(function () {
        resolve("A recovery email has been sent.")
      }).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        reject(errorMessage);
      });

    })
  }
}

export default {
  actions
};