import * as fb from '../../../api/firebase.js';
import { firestoreAction } from 'vuexfire'

const state = {
    faq: []
}

const mutations = {
    SETFAQ(state, payload) {
        state.faq = payload.helpArray
    }
}

const getters = {
    faq: state => state.faq
}

const actions = {
    getHelp: firestoreAction((context, payload) => {
        let helpList = fb.faqCollection
            .where("online", "==", true)
            .orderBy("datetime", 'asc');
        helpList.get().then((querySnapshot) => {
            let helpArray = []
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                let questList = fb.faqCollection.doc(doc.id).collection("questions")
                    .where("online", "==", true)
                    .orderBy("datetime", "asc");
                questList.get().then((questSnapshot) => {
                    let questArray = []
                    questSnapshot.forEach((qdoc) => {
                        const questData = qdoc.data()
                        questArray.push(questData)
                    })
                    data.questions = questArray
                    helpArray.push(data)
                })
            })
            context.commit("SETFAQ", { helpArray })
        })
    })
}

export default {
    state,
    mutations,
    getters,
    actions
}