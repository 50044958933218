import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    adminUsersList: [],
    firstVisibleUsersAll: "",
    lastVisibleUsersAll: "",
    next_users_all: false,
    prev_users_all: false,
    limitAll: 10,
    freeUsers: [],
    corpUsers: []
}

const getters = {
    adminUsersList: state => state.adminUsersList,
    firstVisibleUsersAll: state => state.firstVisibleUsersAll,
    lastVisibleUsersAll: state => state.lastVisibleUsersAll,
    next_users_all: state => state.next_users_all,
    prev_users_all: state => state.prev_users_all,
    freeUsers: state => state.freeUsers,
    corpUsers: state => state.corpUsers
}


const actions = {
    resetAdminUsersList(context, payload) {
        state.firstVisibleUsersAll = "";
        state.lastVisibleUsersAll = "";
        state.next_users_all = false;
        state.prev_users_all = false;
    },
    getFullAdminUsersList(context, payload) {
        return new Promise((resolve, reject) => {
            fb.usersCollection
            .orderBy("joined", "desc")
            .get()
            .then((querySnapshot) => {
                let usersArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    usersArray.push(data)
                })
                resolve(usersArray)
            })
        });
    },
    getNextAdminUsersList(context, payload) {
        fb.usersCollection
            .orderBy("joined", "desc")
            .startAfter(state.lastVisibleUsersAll)
            .limit(state.limitAll)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleUsersAll = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleUsersAll = querySnapshot.docs[0];
                let usersArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    usersArray.push(data)
                })
                state.adminUsersList = usersArray;

                fb.usersCollection
                    .orderBy("joined", "desc")
                    .startAfter(state.lastVisibleUsersAll)
                    .limit(state.limitAll)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_users_all = false
                            state.prev_users_all = true
                        } else {
                            state.next_users_all = true
                            state.prev_users_all = true
                        }
                        if (payload.firstTime) {
                            state.prev_users_all = false
                        }
                    })
            })
    },
    getPrevAdminUsersList(context, payload) {
        fb.usersCollection
            .orderBy("joined", "desc")
            .endBefore(state.firstVisibleUsersAll)
            .limitToLast(state.limitAll)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleUsersAll = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleUsersAll = querySnapshot.docs[0];
                let usersArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    usersArray.push(data)
                })
                state.adminUsersList = usersArray;

                fb.usersCollection
                    .orderBy("joined", "desc")
                    .endBefore(state.firstVisibleUsersAll)
                    .limitToLast(state.limitAll)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_users_all = true
                            state.prev_users_all = false
                        } else {
                            state.next_users_all = true
                            state.prev_users_all = true
                        }
                    })
            })
    },
    getAdminUsersByEmail(context, payload) {
        let email = payload.email.toLowerCase()
        if (email != "") {
            state.firstVisibleUsersAll = "";
            state.lastVisibleUsersAll = "";
            state.next_users_all = false;
            state.prev_users_all = false;
            fb.usersCollection
                .where("email", "==", email)
                .get()
                .then((querySnapshot) => {
                    let usersArray = []
                    querySnapshot.forEach((doc) => {
                        const data = doc.data()
                        data.id = doc.id
                        usersArray.push(data)
                    })
                    state.adminUsersList = usersArray;
                })
        }
    },
    getFreeUserStatus(context, payload) {
        fb.freeUsersCollection
            .where("userid", "==", payload.userid)
            .get()
            .then((querySnapshot) => {
                state.freeUsers = querySnapshot.docs.map(doc => doc.data())
            })
    },
    getCorpUserState(context, payload) {
        fb.corpUsersCollections
            .where("user.userid", "==", payload.userid)
            .get()
            .then((querySnapshot) => {
                state.corpUsers = querySnapshot.docs.map(doc => doc.data())
            })
    },
    updateFreeUserStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateStatus = fb.functions.httpsCallable("updatefreeuseronlinestatus");
            updateStatus({
                status: status.status,
                userid: status.uid
            })
            resolve()
        })
    },
    updateVectorCoachListAll(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateStatus = fb.functions.httpsCallable("vectorizecoachlist");
            updateStatus({
                status: status.status,
                userid: status.uid
            })
            resolve()
        })
    },
    updateFreeUserExpirationDate(context, status) {
        return new Promise(async (resolve, reject) => {
            let expireDate = status.expireDate
            let userid = status.uid
            let user = await fb.firebase.getCurrentUser()
            fb.changedExipreDateCollection
                .add({
                    userid: userid,
                    expiredate: expireDate,
                    changeby: user.uid
                })
            var updateStatus = fb.functions.httpsCallable("updatefreeuserexpirestatus");
            updateStatus({
                expiredate: expireDate,
                userid: userid
            })
            resolve()
        })
    },
    createCoachNotification(context, status) {
        return new Promise(async (resolve, reject) => {
            resolve()
        })
    },
    createFreeTrial(context, form) {
        return new Promise(async (resolve, reject) => {
            let profileLink = ""
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            if (form.profilephoto !== "" && form.profilephoto !== null) {
                let profilefirstPhoto = form.profilephoto.replace('data:image/jpeg;base64,', '')
                let profilenewPhoto = profilefirstPhoto.replace('data:image/png;base64,', '')
                var profileref = fb.storage.child('coaches/' + result + "-profile" + '.jpg')
                let profilesnapshot = await profileref.putString(profilenewPhoto, 'base64')
                profileLink = await profilesnapshot.ref.getDownloadURL()
            }

            let formData = {
                firstname: form.firstname,
                email: form.email,
                password: form.password,
                city: form.city,
                profilelink: profileLink,
                expirationdate: form.expirationdate,
                title: form.title
            }

            fb.usersCollection
                .where("email", "==", form.email.trim().toLowerCase())
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        reject("That email is already associated with an account.")
                    } else {
                        var createFreeTrial = fb.functions.httpsCallable("createfreetrial");
                        createFreeTrial(formData)
                        resolve("new")
                    }
                })
        });
    }

}

export default {
    state,
    getters,
    actions
}
